.categories-list {
	display: flex;
	align-items: center;
	gap: 5px;
	height: 20px;

	.categories-element {
		padding: 2px 6px;
		background-color: #ecebeb;
		border-radius: 10px;
		font-size: 12px;
	}
}
