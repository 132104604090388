.staff-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.staff-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			display: none;
		}
	}

	.staff-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.info-title {
			// font-size: 16px;
			font-weight: bold;
		}
		.info-subtitle {
			font-size: 12px;
		}
	}

	.staff-card-preview {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.categories-list {
			margin: 0px auto;
		}
	}
}

@media (max-width: 991px) {
	.staff-card-container {
		.staff-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 767px) {
	.staff-card-container {
		.staff-card-info {
			.info-title {
				font-size: 14px;
			}
		}
		.staff-card-preview {
			display: flex;
		}
	}
}

@media (max-width: 575px) {
	.staff-card-container {
		gap: 2px;
		.staff-card-header {
			line-height: 0.8;
			.top-right {
				display: flex;
			}
		}
		.staff-card-info {
			height: 30px;
		}
		.staff-card-preview {
			display: none;
		}
	}
}
