.breadcrumbs-block-container {
	align-items: center;
	margin-bottom: 5px;
	height: 35px;

	&.empty {
		height: auto;

		.breadcrumbs-container {
			background-color: transparent;
			border: 0 none;
			padding: 0px;
		}
	}

	.breadcrumbs-container {
		display: flex;
		align-items: center;
		gap: 20px;
		border: 1px solid #ebf1fd;
		background-color: #ebf1fd;
		border-radius: 10px;
		padding: 4px 12px;

		&.tabbed {
			gap: 10px;
			border: 1px solid #ebf1fd;
			background-color: transparent;
			border-radius: 0px;
			border: 0 none;
			padding: 0px;
		}

		.tab-element {
			border: 1px solid #002160;
			border-radius: 10px;
			padding: 0px 16px;

			&.selected {
				color: #fff;
				background-color: #002160;
			}
		}

		.bc-element {
			&.clickable {
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}

			&.current {
				font-weight: bold;
				line-height: 1;
			}
		}
	}
}

// @media (max-width: 991px) {
// 	.breadcrumbs-block-container {
// 		.breadcrumbs-container {
// 			width: 100%;
// 		}
// 	}
// }

@media (max-width: 767px) {
	.breadcrumbs-block-container {
		flex-direction: column;
		align-items: flex-end;
		gap: 5px;
		height: auto;

		.breadcrumbs-container {
			width: 100%;

			&.not-empty {
				height: 30px;
			}
		}
	}
}
