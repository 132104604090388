.player-container {
	.stats-player-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 10px;
		border: 1px solid #ebf1fd;
		padding: 5px 20px;
		background-color: #ebf1fd;
		position: relative;

		&.attendance {
			background-color: #fff;
			border: 2px solid #ebf1fd;
		}

		&.rpe {
			padding: 5px 30px;
		}

		.MuiButtonBase-root {
			padding: 0px;
		}

		.stats-action {
			position: absolute;
			top: 0px;
			right: 05px;
		}

		.player-icon {
			width: 35px;
			height: 35px;
		}

		.player-name {
			font-weight: bold;
			line-height: 1;

			&.match {
				min-width: 200px;
			}

			&.clickable {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.player-stat-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			line-height: 1;

			.stat-value {
				font-size: 16px;
				font-weight: bold;
				min-width: 75px;
				text-align: center;
			}

			.stat-label-icon {
				width: 10px;
				height: 10px;
				border-radius: 50%;

				&.on {
					background-color: #67af67;
				}

				&.off {
					background-color: #e45858;
				}
			}
		}
		.stats-container {
			gap: 100px;
		}
	}
}

.match-player-stats {
	display: flex;
	align-items: center;
	// flex-direction: column;
	padding: 0px;
	gap: 10px;
	width: 100%;

	.match-stats-details-container {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 250px;
		width: 100%;
		gap: 5px;
		display: flex;
		flex-flow: column;
	}

	.player-info {
		border: 1px solid #ebf1fd;
		border-radius: 10px;
		background-color: #ebf1fd;
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding: 5px 20px;
		height: 100px;
		justify-content: center;

		.main-stat {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.number {
				font-weight: bold;
			}
		}

		.player-name {
			font-weight: bold;
			line-height: 1;
			min-width: 200px;

			&:hover {
				text-decoration: underline;
			}
		}

		.MuiSvgIcon-root {
			width: 18px;
			height: 18px;
		}
	}

	.match-stats {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex: 1;
		width: 100%;

		&.detailed {
			.stats-group {
				height: 60px;
				background-color: #f7f7f7;
				padding: 8px 4px;

				.group-title {
					display: none;
				}

				.group-items {
					.player-stat {
						height: 45px;

						.circle {
							width: 25px;
							height: 25px;
							border: 1px solid #002160;

							.number {
								font-size: 14px;
							}
						}
					}
				}
			}
		}

		.stats-group {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 5px;
			margin: 5px;
			padding: 10px 5px;
			height: 100px;
			background-color: #ebf1fd;
			border-radius: 10px;

			.group-title {
				font-weight: bold;
				margin: 0px 5px;
				line-height: 1;
				min-height: 14px;
			}

			.group-items {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				.player-stat {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					margin: 0px 5px;
					height: 70px;

					.circle {
						width: 45px;
						height: 45px;
						background-color: #fff;
						border: 3px solid #002160;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
						position: relative;

						.number {
							font-size: 14px;
							font-weight: bold;
						}
					}
					.label {
						font-size: 12px;
						line-height: 1;
						margin-top: 5px;
						text-align: center;
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.match-player-stats {
		flex-direction: column;

		.player-info {
			width: 100%;
			border-radius: 10px 10px 0px 0px;
			// flex-direction: row;
			// align-items: center;
			// justify-content: space-between;

			.main-stat {
				// gap: 5px;
			}
		}

		.match-stats {
			.stats-group {
				.group-items {
					.player-stat {
						justify-content: flex-start;
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.match-player-stats {
		.match-stats {
			.stats-group {
				width: 100%;
				align-items: center;
			}
		}
	}
}

@media (max-width: 991px) {
	.player-container {
		.stats-player-container {
			padding: 10px 20px;
			flex-direction: column;

			&.attendance {
				flex-direction: row;
				gap: 5px;

				.player-icon {
					width: 30px;
					height: 30px;
				}

				.player-name {
					&.match {
						min-width: 200px;
					}
				}

				.MuiButtonBase-root {
					height: 25px;
					padding: 0px;

					.MuiSvgIcon-root {
						height: 18px;
					}
				}
			}

			.player-name {
				&.match {
					min-width: fit-content;
				}
			}

			.stats-container {
				gap: 35px;
			}
		}
	}
}

@media (max-width: 575px) {
	.player-container {
		.stats-player-container {
			&.attendance {
				flex-direction: column;

				.player-name {
					&.match {
						min-width: fit-content;
					}
				}
			}
		}
	}
}
