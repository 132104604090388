.tqr-stat-card-container {
	display: flex;
	flex-direction: column;
	gap: 2px;

	.tqr-stat-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 14px;
		}
	}

	.tqr-stat-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.2;
		gap: 5px;

		.info-title {
			font-weight: bold;
			font-size: 20px;
			display: flex;
			align-items: center;
			flex-direction: column;
			line-height: 1;

			.tqr-stat-info {
				padding: 2px 10px;
				border-radius: 10px;

				&.light {
					background-color: lightgreen;
				}
				&.medium {
					background-color: #ffff87;
				}
				&.strong {
					background-color: #ff9696;
				}
			}
		}
	}
}
