.login-page-container {
	display: grid;
	place-items: center;
	margin: 0;
	height: 100vh;
	background: #eff9ff;

	.background {
		position: fixed;
		top: -55vmin;
		left: -60vmin;
		width: 100vmin;
		height: 100vmin;
		border-radius: 47% 53% 61% 39% / 45% 51% 49% 55%;
		background: $color-primary;
	}

	.MuiLoadingButton-root .MuiCircularProgress-root {
		color: white;
	}

	.login-container {
		z-index: 1;
		padding: 0px 40px;

		.logo {
			width: 400px;
		}

		.form-container {
			display: flex;
			align-items: center;
			flex-flow: column;

			.application-title {
				color: $color-primary;
				font-weight: bold;
				font-size: 32px;
				letter-spacing: 2px;
				margin-bottom: 25px;
			}

			.action {
				display: flex;
				align-items: center;
				justify-content: center;

				.MuiButtonBase-root {
					min-width: 150px;
					background-color: $color-primary;
					// color: white;
				}
			}
		}
	}

	.background::after {
		content: "";
		position: inherit;
		bottom: -55vmin;
		right: -60vmin;
		width: inherit;
		height: inherit;
		border-radius: inherit;
		background: $color-primary;
		color: white;
	}
}

@media (max-width: 991px) {
	.login-page-container {
		.background {
			top: -60vmin;
			left: -65vmin;
		}

		.background::after {
			bottom: -60vmin;
			right: -65vmin;
		}

		.login-container {
			.logo {
				width: 350px;
			}
		}
	}
}

@media (max-width: 767px) {
	.login-page-container {
		.background {
			top: -65vmin;
			left: -70vmin;
		}

		.background::after {
			right: -65vmin;
			bottom: -70vmin;
		}

		.login-container {
			.logo {
				width: 300px;
			}
		}
	}
}
