.view-field-container {
	display: flex;
	flex-flow: column;
	width: 100%;
	margin: 10px 5px;
	// text-align: center;
	line-height: 1.4;

	.label {
		font-weight: bold;
	}

	.cell {
		width: 20px;
		height: 20px;
		border-radius: 50%;

		&.on {
			background-color: #67af67;
		}

		&.off {
			background-color: #e45858;
		}
	}
}
