.players-filters-container {
	display: flex;
	align-items: center;
	gap: 10px;

	.players-filter {
		width: 50%;
	}
}

.players-list-container {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}

@media (max-width: 767px) {
	.players-filters-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 0px;

		.players-filter {
			width: 100%;
		}
	}
}
