.player-container {
	.view-field-container {
		&.avg {
			// border-left: 1px solid #ebf1fd;
			border-top: none;
		}
	}
	.area-rating-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		min-height: 150px;
		border-right: 1px solid #ebf1fd;
		border-bottom: 1px solid #ebf1fd;

		&.only-text {
			min-height: auto;
		}

		.MuiBox-root {
			.MuiCircularProgress-root {
				height: 50px !important;
				width: 50px !important;
			}

			.MuiTypography-root {
				font-size: 16px !important;
				font-weight: bold !important;
				color: #000;
			}
		}

		.empty-data-container {
			flex: 1;
		}

		.rating-notes {
			font-style: italic;
			max-height: 100px;
			overflow-x: hidden;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			padding: 20px 5px;
			background-color: #ebf1fd;

			&.big {
				max-height: 250px;
			}
		}
	}

	.notes-box {
		height: 150px;
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.stat-circle-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;

	.stat-container {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 3px solid #002160;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;

		.value {
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.parameter-container {
	.parameter-total {
		min-width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ebf1fd;
		height: 40px;

		&.positive {
			background-color: #b3f994;
		}

		&.negative {
			background-color: #ff9191;
		}
	}
}

@media (max-width: 992px) {
	.player-container {
		.view-field-container {
			&.avg {
				border-left: none;
				border-top: 1px solid #ebf1fd;
			}
		}
	}
}
