.measurements-container {
	margin-top: 35px;
}

.stat-options {
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 10px;

	.stat-switch {
		display: flex;
		align-items: center;
		gap: 10px;

		.single-option {
			border: 1px solid #002160;
			border-radius: 10px;
			padding: 4px 16px;
			text-align: center;
			line-height: 1.2;

			&.selected {
				color: #fff;
				background-color: #002160;

				&:hover {
					background-color: #002160;
				}
			}

			&:hover {
				background-color: #ebf1fd;
			}
		}
	}

	.react-datepicker__input-container {
		input {
			height: 35px !important;
		}
	}
}

.players-stats {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.single-player-stat {
		.player-name {
			margin: 0px 10px;
			font-weight: bold;
		}

		.table-container {
			margin: 0;
		}
	}
}
