.groups-container {
	display: flex;
	flex-direction: column;
	gap: 15px;

	&.middle-height {
		height: 600px;
		overflow-y: auto;
	}

	&.filters {
		height: calc(var(--doc-height) - 35px - 50px);
		overflow-y: auto;
		overflow-x: hidden;
	}

	&.tabbed {
		height: calc(var(--doc-height) - 35px - 50px);
		overflow-y: auto;
		overflow-x: hidden;

		&.fit-height {
			min-height: 100px;
			height: auto;
		}
	}

	&.breadcrumbs {
		height: calc(var(--doc-height) - 40px - 50px);
		overflow-y: auto;
		overflow-x: hidden;

		&.tabbed {
			height: calc(var(--doc-height) - 75px - 50px);
		}

		&.filters {
			height: calc(var(--doc-height) - 65px - 50px);
		}

		&.fixed-filters {
			height: calc(var(--doc-height) - 130px - 50px);
		}
	}

	&.fixed-filters {
		height: calc(var(--doc-height) - 95px - 50px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 15px;
	}
}

.entities-container {
	align-items: center;

	&.scrollable {
		max-height: 300px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&.grouped {
		.group-title {
			font-weight: bold;
			font-size: 16px;
		}
	}

	&.compact {
		.single-entity-container {
			min-height: 120px;
			max-height: 135px;

			&.today {
				border-color: #002160;
				background-color: #1876d2;
				color: #fff;
			}

			&.players {
				min-height: 70px;
				gap: 0px;

				&.loan {
					opacity: 0.5;
				}
			}

			&.category {
				padding: 5px 10px;
				min-height: 85px;
				gap: 0px;
			}

			&.club {
				padding: 5px 10px;
				min-height: 85px;
				gap: 0px;
			}

			&.exercise {
				min-height: 85px;
				gap: 0px;
			}

			&.general {
				min-height: 95px;
			}

			&.gps {
				max-height: 250px;
				gap: 0px;

				&.total {
					background-color: #ebf1fd;
				}
			}

			&.match {
				min-height: 100px;
				gap: 0px;
			}

			&.match_calendar {
				max-height: 145px;
				gap: 0px;
			}

			&.match_report {
				min-height: 80px;
			}

			&.medical {
				min-height: 90px;
			}

			&.player_movement {
				min-height: 125px;
				max-height: 140px;
			}

			&.roster_player {
				justify-content: center;
				min-height: 60px;
				gap: 0px;
			}

			&.rpe {
				min-height: 90px;
				gap: 0px;
			}

			&.rpe_stat {
				min-height: 100px;
				gap: 0px;
				justify-content: center;

				&.total {
					background-color: #ebf1fd;
					border-color: #ebf1fd;

					.separator {
						border-color: #fff;
					}
				}
			}

			&.report {
				min-height: 130px;
				max-height: 190px;
				gap: 0px;
			}

			&.scout_club {
				min-height: 75px;
				justify-content: center;
			}

			&.scout_coach {
				min-height: 85px;
			}

			&.scout_player {
				min-height: 90px;
			}

			&.staff {
				min-height: 100px;
				max-height: 100px;
				gap: 0px;

				.separator {
					display: none;
				}
			}

			&.target {
				min-height: 90px;
				gap: 0px;
			}

			&.test {
				min-height: 75px;
				gap: 0px;
			}

			&.test_base {
				min-height: 40px;
				gap: 0px;
			}

			&.tqr {
				min-height: 120px;
				gap: 0px;
			}

			&.tqr_stat {
				min-height: 70px;
				gap: 0px;
				// justify-content: center;
			}

			&.training {
				min-height: 100px;
				gap: 0px;
			}

			&.users {
				min-height: 50px;
			}

			&.valuation {
				min-height: 50px;
			}

			&.video {
				min-height: 80px;
			}

			// &.new {
			// 	.new-content {
			// 		font-size: 36px;
			// 	}
			// }

			.entity-data-container {
				display: flex;
				flex-direction: column;
				flex: 1;

				.main-info-container {
					flex-direction: row;

					.info {
						width: auto;
					}
				}
			}
		}
	}

	.single-entity-container {
		display: flex;
		flex-direction: column;
		position: relative;
		gap: 8px;
		padding: 10px 15px;
		box-shadow: 2px 2px 2px 2px #ebebeb;
		border-radius: 10px;
		background-color: #fff;
		margin: 5px;
		// min-height: 175px;
		// max-height: 200px;
		border: 1px solid #fff;

		// &.new {
		// 	background-color: #ebf1fd;
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: center;

		// 	&:hover {
		// 		background-color: #0d2d69;
		// 		color: #fff;
		// 	}

		// 	.new-content {
		// 		font-size: 42px;
		// 	}

		// 	.new-content-label {
		// 		font-size: 18px;
		// 		letter-spacing: 2px;
		// 	}
		// }

		&.warning {
			background-color: #ffcece;
			border-color: #ffcece;
		}

		&.shadow {
			background-color: #d9d9d9;
			border-color: #d9d9d9;

			&:hover {
				.separator {
					border: 0.5px solid #fff !important;
				}
			}
		}

		.entity-date {
			width: 100%;
			font-size: 12px;
			font-style: italic;
		}

		.entity-title {
			font-weight: bold;
			margin-bottom: 2px;
			// font-size: 16px;
		}

		.entity-status {
			position: absolute;
			top: 2px;
			right: 2px;
			// font-size: 12px;
			// border-radius: 0px 10px 0px 10px;
			// padding: 2px 8px;
			width: 15px;
			height: 15px;
			border-radius: 50%;

			&.top {
				background-color: #67af67;
			}
			&.middle {
				background-color: #efea59;
			}
			&.bottom {
				background-color: #e45858;
			}
		}

		.entity-icon {
			position: absolute;
			bottom: 2px;
			right: 2px;

			.MuiSvgIcon-root {
				color: #fa6161;
				width: 20px;
			}
		}

		.main-info-container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;
			flex-flow: column;
			width: 100%;
			flex: 1;

			// da cambiare
			// &.staff {
			// 	flex-direction: column;
			// 	gap: 5px;

			// 	.image {
			// 		img {
			// 			height: 40px;
			// 		}
			// 	}
			// }
		}

		.image {
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;

			img {
				height: 40px;
			}

			&.logo {
				position: absolute;
				top: 10px;
				right: 10px;

				img {
					width: 20px;
					height: 25px;
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			flex: 2;
			align-items: start;
			justify-content: center;
			line-height: 1.5;
			width: 100%;

			.title {
				font-weight: bold;
				// text-align: center;
				line-height: 1.2;
			}

			.subtitle {
				font-size: 13px;
				color: #7f7c7c;

				.progress-container {
					gap: 10px;

					.progress-title {
						color: #212529;
						font-weight: bold;
					}

					.MuiTypography-root {
						font-weight: bold;
						font-size: 1rem;
					}
				}
			}

			.text {
				text-align: center;
				line-height: 1.2;
				width: 100%;
			}
		}

		.separator {
			border: 0.5px solid #ebebeb;
			width: 100%;

			&.vertical {
				width: 1px;
				height: 55px;
			}
		}

		.actions {
			display: flex;
			align-items: center;
			gap: 15px;

			.action {
				.action-content {
					gap: 5px;
				}

				.MuiButtonBase-root {
					height: 25px;
				}
			}
		}

		&:hover:not(.disabled) {
			cursor: pointer;
			border: 1px solid #1976d2;

			.separator {
				border: 0.5px solid darken(#ebebeb, 5);
				width: 100%;
			}
		}
	}
}

@media (max-width: 991px) {
	.entities-container {
		.single-entity-container {
			font-size: 13px;

			.info {
				.subtitle {
					.progress-container {
						font-size: 12px;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.groups-container {
		&.filters {
			height: calc(var(--doc-height) - 45px - 50px);
		}

		&.breadcrumbs {
			height: calc(var(--doc-height) - 55px - 50px);

			&.filters {
				height: calc(var(--doc-height) - 80px - 50px);
			}

			&.fixed-filters {
				height: calc(var(--doc-height) - 140px - 50px);
			}
		}

		&.fixed-filters {
			height: calc(var(--doc-height) - 105px - 50px);
		}
	}

	.entities-container {
		&.compact {
			.single-entity-container {
				min-height: 125px;
				max-height: 125px;
			}
		}

		&.no-margin {
			.entities-columns {
				padding-right: 5px !important;
				padding-left: 5px !important;
			}
		}

		.single-entity-container {
			padding: 6px 12px;
			margin-left: 0px;
			margin-right: 0px;

			.image {
				img {
					height: 35px;
				}
			}

			.info {
				.subtitle {
					.progress-container {
						gap: 25px;
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.entities-container {
		&.compact {
			.single-entity-container {
				&.staff {
					min-height: 75px;
					max-height: 75px;
					padding: 9px 12px;

					.entity-data-container {
						display: flex;
						flex-direction: row;

						.main-info-container {
							flex: 2;
						}
						.separator {
							display: none;
						}

						.info {
							line-height: 1.2;

							.categories-list {
								justify-content: center;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}
