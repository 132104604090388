.cards-container {
	.cards-table-container {
		display: block;
	}

	.cards-list-container {
		display: none;
	}
}

@media (max-width: 1199px) {
	.cards-container {
		.cards-table-container {
			display: none;
		}

		.cards-list-container {
			display: block;
		}
	}
}
