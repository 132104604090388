.page-content {
	flex: 1;
	width: 100%;

	.header-container {
		height: 0px;
	}

	.page-body {
		position: relative;
		background: linear-gradient(
			to right,
			#f7f7f7 0%,
			#f8f8f8 50%,
			#f7f7f7 100%
		);
		padding: 5px 10px;
		// border-radius: 0px 10px 10px 0px;

		.page-header {
			align-items: end;
			border-bottom: 2px solid #ebebeb;
			padding-top: 5px;
			padding-bottom: 5px;
			margin-bottom: 10px;
			height: 45px;

			.title {
				font-size: 24px;
				font-weight: bold;
				letter-spacing: 1px;
			}

			.actions {
				display: flex;
				align-items: center;
				gap: 5px;

				.action {
					color: #fff;

					.MuiButton-containedGreen {
						color: #fff;

						.action-content {
							gap: 5px;
						}
					}
				}
			}
		}

		.page-children {
			position: relative;
			height: calc(var(--doc-height) - 10px - 50px);
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}

@media (max-width: 991px) {
	.page-content {
		.page-body {
			border-radius: 0px;

			.page-header {
				.title {
					font-size: 22px;
				}

				.actions {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.page-content {
		.page-body {
			.page-header {
				.title {
					font-size: 18px;
				}
			}

			.page-children {
				height: calc(var(--doc-height) - 10px - 60px);
			}
		}
	}
}
