.rpe-stat-card-container {
	display: flex;
	flex-direction: column;
	gap: 2px;

	&.total {
		height: 100%;
		display: flex;
		align-items: center;

		.rpe-stat-card-info {
			flex-direction: row;
			gap: 25px;
		}
	}

	.rpe-stat-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 14px;
		}
	}

	.rpe-stat-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.2;
		gap: 5px;

		.stat-img-container {
			display: flex;
			justify-content: center;
			img {
				width: 35px;
			}
		}

		.info-title {
			font-weight: bold;
			font-size: 20px;
			display: flex;
			align-items: center;
			flex-direction: column;
			line-height: 1;

			.rpe-stat-sub-info {
				font-size: 12px;
				font-weight: 400;
			}
		}
		.info-subtitle {
			font-size: 13px;
			text-align: center;
			background-color: #ebf1fd;
			padding: 2px 10px;
			border-radius: 10px;

			&.light {
				background-color: #ffff87;
			}
			&.medium {
				background-color: lightgreen;
			}
			&.strong {
				background-color: #ff9696;
			}
		}
	}
}

// @media (max-width: 991px) {
// 	.rpe-stat-card-container {
// 		.rpe-stat-card-info {
// 			.info-title {
// 				font-size: 12px;
// 			}
// 		}
// 	}
// }

// @media (max-width: 767px) {
// 	.rpe-stat-card-container {
// 		.rpe-stat-card-info {
// 			.info-title {
// 				font-size: 14px;
// 			}
// 		}
// 	}
// }
