body {
	margin: 0;
	font-size: 14px;
	font-family:
		"Roboto",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root {
	--doc-height: 100%;
}

.selected-text {
	font-weight: bold;
	color: $color-primary;
}

.layout-container {
	width: 100%;
	height: 100%;
	overflow: hidden;

	.layout {
		display: flex;
		flex-flow: column;
		width: 100%;
		height: 100%;

		.circle {
			width: 100px;
			height: 100px;
			background-color: $color-primary;
			border-radius: 50%;
			position: absolute;

			&.bottom {
				bottom: 0px;
				right: 0px;
			}
		}

		.layout-side {
			flex-direction: column;
			background-color: #e1e1e1;
		}
	}
}

.row {
	&.no-margin {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.no-padding {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}

	.no-padding-right {
		padding-right: 0px !important;
		padding-left: 5px !important;
	}
	.no-padding-left {
		padding-left: 0px !important;
		padding-right: 5px !important;
	}

	.padding-small {
		padding-right: 5px !important;
		padding-left: 5px !important;
	}
	.padding-right-small {
		padding-right: 5px !important;
	}
	.padding-left-small {
		padding-left: 5px !important;
	}
}

.clickable {
	cursor: pointer;
}

.f-bold {
	font-weight: bold;
}

.padded-right {
	padding-right: 24px;
}

.MuiPaper-root {
	overflow-y: visible !important;
}

.MuiLoadingButton-root {
	background-color: #fff;
}

.MuiButtonBase-root {
	min-width: 40px !important;
}

.MuiButtonGroup-root {
	border-color: #cbcbcb;

	.MuiButtonBase-root {
		font-weight: 400;
		text-transform: none;

		&:hover:not(.MuiButton-contained) {
			border-color: #cbcbcb;
			background-color: #ebf1fd;
		}
	}
}

.field-label-container {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.field-label {
		font-weight: bold;
	}
	.field-required {
		font-weight: bold;
	}
}
.field-value {
	display: flex;
	align-items: center;
	height: 40px;
	font-size: 16px;
}
.MuiFormControl-root {
	margin-top: 0px !important;
}
.MuiFormControlLabel-root {
	height: 40px;
}
.MuiFormGroup-root {
	.MuiFormControlLabel-root {
		height: 30px;
		.MuiTypography-root {
			font-size: 14px;
		}
	}
}

.header-menu-container {
	background-color: #fff;
	color: #fff;

	.logo {
		display: flex;
		height: 100%;
		min-width: 225px;
		background-color: #fcfcfc;
		cursor: pointer;
		background-image: url("../assets/images/logo.png");
		background-size: 70%;
		background-repeat: no-repeat;
		background-position: center 0%;
	}

	.header-menu-items {
		background-color: $color-primary;
		display: flex;
		flex-direction: row;
		width: 100%;

		.header-menu-item {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;
			flex: 1;
			height: 50px;
			border-right: 1px solid lighten($color-primary, 75);
			border-bottom: 0px;
			cursor: pointer;

			img {
				width: 30px;
				height: 30px;
			}

			span {
				font-size: 14px;
			}

			&.selected {
				background-color: darken($color-primary, 5);

				&:hover {
					background-color: darken($color-primary, 5);
				}
			}

			&:last-child {
				border-right: 0px;
			}

			&:hover {
				background-color: #263859; //darken($color-primary, 10);
			}

			&.user {
				flex: none;
				min-width: 50px;
				background-color: lighten($color-primary, 75);
				position: relative;

				img {
					border: 2px solid $color-primary;
					border-radius: 50%;
					background-color: #fff;
				}

				.user-menu {
					position: absolute;
					top: 60px;
					right: 0px;
					background-color: #fff;
					border: 1px solid $color-primary;
					width: 200px;
					z-index: 1;
					padding: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-shadow: 5px 5px 10px lightgray;

					span {
						font-size: 12px;
					}
				}
			}
		}
	}
}

.sheet-container {
	height: calc(var(--doc-height) - 50px - 50px);
	overflow-y: auto;
	overflow-x: hidden;
}

@media (max-width: 991px) {
	.layout-container {
		.layout {
			.circle {
				display: none;
			}

			.layout-side {
				padding: 0px;
			}

			.col-container {
				display: flex;
				flex-flow: column;
				gap: 5px;
			}
		}
	}

	.header-menu-container {
		.header-menu-items {
			.item-label {
				display: none;
			}
		}
	}
}

@media (max-width: 767px) {
	.header-menu-container {
		display: flex;
		flex-direction: column;

		.header-menu-items {
			display: none;
		}

		.logo {
			height: 60px;
			width: 100%;
			background-size: 25%;
			background-position: center 50%;
		}
	}

	.hide-mobile {
		display: none;
	}

	.sheet-container {
		height: calc(var(--doc-height) - 85px - 50px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}
