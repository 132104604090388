.general-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	height: 100%;

	.general-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.card-image {
			img {
				height: 50px;
			}
		}

		.info-title {
			// font-size: 16px;
			font-weight: bold;
		}
	}
}

@media (max-width: 991px) {
	.general-card-container {
		.general-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 767px) {
	.general-card-container {
		.general-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
