.gps-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	height: 100%;

	.main-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		background-color: #ebf1fd;
		border: 1px solid #ebf1fd;
		border-radius: 10px;
		padding: 2px 4px;
	}

	.card-params-container {
		display: flex;
		flex-direction: column;
		gap: 5px;
		overflow-y: auto;
		max-height: 200px;

		.card-param-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e0e0e0;
			font-size: 12px;

			.param-label {
				font-weight: bold;
			}
		}
	}
}

@media (max-width: 991px) {
	.general-card-container {
		.general-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 767px) {
	.general-card-container {
		.general-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
