.parameters-area {
	.parameters-image {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		img {
			width: 100px;
			height: 100px;
		}
	}

	.parameter-container {
		// display: flex;
		// align-items: center;
		.parameter-total {
			min-width: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 20px;
			background-color: #ebf1fd;
			height: 40px;
		}
	}
}
