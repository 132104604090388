.choose-saved-container {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 5px;

	.save-option {
		padding: 4px 12px;
		border-radius: 10px;
		border: 1px solid #ebf1fd;
		cursor: pointer;

		&:hover:not(.selected) {
			color: #fff;
			background-color: #002160;
			border-color: #002160;
		}

		&.selected {
			background-color: #ebf1fd;
		}
	}
}
