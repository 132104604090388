.chart-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 300px;
	margin-top: 5px;

	.chart-title {
		font-weight: bold;
	}
}
