.video-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.video-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 12px;
		}
	}

	.video-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.info-title {
			font-size: 18px;
			font-weight: bold;
		}
		.info-subtitle {
			font-size: 14px;
		}
	}

	.video-card-preview {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.video-type {
			display: flex;
			align-items: center;
			gap: 3px;

			img {
				width: 25px;
			}

			.type-info {
				display: flex;
				align-items: center;
				font-size: 12px;
			}
		}

		.video-stats {
			display: flex;
			align-items: center;
			gap: 12px;

			.single-stat {
				display: flex;
				align-items: center;
				gap: 5px;
				font-weight: bold;

				.value {
					flex: none;
					width: 20px;
					height: 20px;
					border-radius: 50%;

					&.inserted {
						background-color: #67af67;
					}

					&.empty {
						background-color: #e45858;
					}
				}

				img {
					width: 25px;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.video-card-container {
		.video-card-info {
			.info-title {
				font-size: 16px;
			}
			.info-subtitle {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 767px) {
	.video-card-container {
		.video-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
