.roster-player-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	&.selected {
		.info-title div {
			text-decoration: underline;
		}
	}

	.roster-player-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			position: absolute;
			left: 4px;

			img {
				width: 14px;
				height: 18px;
			}
		}

		.top-right {
			position: absolute;
			right: 2px;

			.card-actions-container {
				display: flex;
				align-items: center;
				gap: 5px;

				.MuiButtonBase-root {
					padding: 2px;

					.MuiSvgIcon-root {
						width: 18px;
						height: 18px;
					}
				}
			}
		}
	}

	.roster-player-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.info-title {
			font-size: 13px;
			display: flex;
			align-items: center;
			gap: 10px;
			width: 170px;

			img {
				width: 30px;
			}
		}
		.info-subtitle {
			font-size: 14px;
		}
	}
}
