.switch-view-container {
	position: absolute;
	top: 0px;
	left: 0px;

	&.fixed {
		position: relative;
		margin-bottom: 15px;
	}

	.MuiButtonBase-root {
		height: 25px;
	}
}
