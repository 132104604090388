.valuation-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.valuation-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 12px;
		}
	}

	.valuation-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		flex-flow: row;

		img {
			width: 40px;
		}

		.info-title {
			font-weight: bold;
			font-size: 14px;
			min-width: 150px;
			line-height: 1;
		}

		.info-second-title {
			font-size: 12px;
		}

		.info-subtitle {
			border-radius: 10px;
			padding: 3px 6px;
			background-color: #ebf1fd;
		}
	}

	.valuation-card-preview {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.valuation-type {
			display: flex;
			align-items: center;
			gap: 5px;

			img {
				width: 25px;
			}

			.type-info {
				display: flex;
				align-items: center;
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 991px) {
	.valuation-card-container {
		.valuation-card-info {
			img {
				width: 30px;
			}
			.info-title {
				font-size: 12px;
				min-width: 130px;
			}
		}
	}
}

@media (max-width: 767px) {
	.valuation-card-container {
		.valuation-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
