.exercise-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.exercise-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 18px;

		.top-right {
			font-size: 12px;
			background-color: #ebf1fd;
			border-radius: 8px;
			padding: 2px 8px;
		}
	}

	img {
		width: 30px;
	}

	.exercise-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;
		flex: 1;

		.info-title {
			font-weight: bold;
			font-size: 16px;
		}
		.info-subtitle {
			font-size: 14px;
			// font-weight: bold;
		}
	}

	.exercise-card-preview {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.training-type {
			display: flex;
			align-items: center;
			gap: 5px;

			img {
				width: 25px;
			}

			.type-info {
				display: flex;
				align-items: center;
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 991px) {
	.exercise-card-container {
		.exercise-card-info {
			.info-title {
				font-size: 16px;
			}
			.info-subtitle {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 767px) {
	.exercise-card-container {
		.exercise-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
