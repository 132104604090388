.medical-card-container {
	display: flex;
	flex-direction: column;
	// gap: 5px;

	.medical-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 12px;
		}
	}

	.top-center {
		display: flex;
		justify-content: center;

		.match-counter {
			border: 2px solid lightblue;
			border-radius: 50%;
			width: 25px;
			height: 25px;

			.counter {
				font-weight: bold;
				text-align: center;
				height: 100%;
			}
		}
	}

	.medical-card-info {
		display: flex;
		// align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.info-title {
			font-size: 14px;
			font-weight: bold;
		}
		.info-subtitle {
			font-size: 12px;
			// font-weight: bold;
		}
	}

	.medical-card-subinfo {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.type-info {
			display: flex;
			align-items: center;
			font-size: 12px;
		}
	}

	.medical-card-preview {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.match-type {
			display: flex;
			align-items: center;
			gap: 3px;

			img {
				width: 25px;
			}

			.type-info {
				display: flex;
				align-items: center;
				// font-size: 12px;
			}
		}

		.match-stats {
			display: flex;
			align-items: center;
			gap: 12px;

			.single-stat {
				display: flex;
				align-items: center;
				gap: 5px;
				font-weight: bold;

				.value {
					flex: none;
					width: 20px;
					height: 20px;
					border-radius: 50%;

					&.inserted {
						background-color: #67af67;
					}

					&.empty {
						background-color: #e45858;
					}
				}

				img {
					width: 25px;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.medical-card-container {
		.medical-card-info {
			.info-title {
				// font-size: 12px;
			}
			.info-subtitle {
				// font-size: 10px;
			}
		}
	}
}

@media (max-width: 767px) {
	.medical-card-container {
		.medical-card-info {
			.info-title {
				// font-size: 14px;
			}
		}
	}
}
