.MuiCard-root {
	overflow: visible !important;
	border-radius: 10px !important;
	border: 0 none !important;

	&.left-border {
		border-radius: 10px 0px 0px 10px !important;
	}
	&.right-border {
		border-radius: 0px 10px 10px 0px !important;
	}

	.MuiCardContent-root {
		// background-color: #f5f9ff;
		border-radius: 10px;

		&.in-page {
			background-color: #d4e5ff;
		}

		.card-content {
			width: 100%;
			height: 100%;

			.card-title-container {
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
				margin-bottom: 5px;

				.card-title {
					font-weight: bold;
					font-size: 16px;
					line-height: 1.2;
				}

				.card-open {
					cursor: pointer;
				}
			}

			.card-content-buttons {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 5px;
			}
		}
	}
}

@media (max-width: 991px) {
	.MuiCard-root {
		&.left-border {
			border-radius: 10px !important;
		}
		&.right-border {
			border-radius: 10px !important;
		}
	}
}
