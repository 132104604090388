.player-container {
	.image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		&.generic {
			img {
				width: 80px;
				height: 80px;
			}
		}

		img {
			width: 95px;
			height: 115px;
		}
	}

	.player-status {
		// font-weight: 400;
		font-size: 12px;
		background-color: lightgray;
		border-radius: 10px;
		padding: 2px 6px;
	}

	.stat-options {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 10px;

		.stat-switch {
			display: flex;
			align-items: center;
			gap: 10px;

			.single-option {
				border: 1px solid #002160;
				border-radius: 10px;
				padding: 0px 16px;
				text-align: center;
				line-height: 1.2;

				&.selected {
					color: #fff;
					background-color: #002160;

					&:hover {
						background-color: #002160;
					}
				}

				&:hover {
					background-color: #ebf1fd;
				}
			}
		}

		.react-datepicker__input-container {
			input {
				height: 35px !important;
			}
		}
	}

	.stats-box-container {
		display: flex;
		align-items: center;
		gap: 15px;
		flex-wrap: wrap;

		.stat-box {
			// border: 1px solid #002160;
			background-color: #ebf1fd;
			border-radius: 10px;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			flex-direction: column;
			line-height: 1.2;
			flex: 1;

			.stat-value {
				font-size: 18px;
				font-weight: bold;
			}

			.stat-label {
				// font-weight: bold;
			}
		}
	}

	.month-matches-container {
		.month-name {
			font-weight: bold;
			margin-left: 2px;
		}

		.month-values-container {
			border-color: #ebf1fd;
			background-color: #ebf1fd;
			border-radius: 10px;
			padding: 10px 20px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			&.all {
				border: 1px solid #b4d4f4;
				background-color: #fff;
			}

			.match-type-container {
				display: flex;
				align-items: center;
				padding-bottom: 2px;
				border-bottom: 1px solid #b4d4f4;

				.match-type {
					flex: 1;
					font-weight: bold;
				}

				.type-stats-container {
					flex: 1;
					display: flex;
					flex-flow: column;
					align-items: center;
					line-height: 1.2;
					border-left: 1px solid #b4d4f4;

					.type-stat-value {
						font-size: 16px;
						font-weight: bold;
					}
				}
			}
		}
	}

	.trainings-stats-row {
		.table-container {
			display: flex;
		}
	}

	.trainings-stats-container {
		display: none;
	}
}

@media (max-width: 991px) {
	.player-container {
		.image-container {
			justify-content: flex-start;

			&.generic {
				img {
					width: 60px;
					height: 60px;
				}
			}
		}

		.trainings-stats-row {
			.table-container {
				display: none;
			}
		}

		.trainings-stats-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			margin-top: 10px;

			.table-element {
				display: flex;
				flex-direction: column;
				align-items: center;
				border: 1px solid #ebf1fd;
				// border-radius: 10px;
				// gap: 10px;
				width: 100%;

				.element-main-info {
					font-weight: bold;
					font-size: 16px;
					background-color: #ebf1fd;
					width: 100%;
					padding: 2px 4px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 10px;
				}

				.element-info-container {
					gap: 5px;
					justify-content: space-evenly;
					margin: 5px;

					.info-container {
						justify-content: center;
						// width: 60px;

						.label {
							font-size: 11px;
							text-align: center;
							min-height: 30px;
							line-height: 1;
						}

						.value {
							font-size: 18px;
							font-weight: bold;
						}
					}

					.separator {
						width: 1px;
						height: 35px;
						background-color: #ebf1fd;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.player-container {
		.image-container {
			justify-content: center;

			&.generic {
				img {
					width: 60px;
					height: 60px;
				}
			}
		}

		.stats-box-container {
			.stat-box {
				font-size: 12px;
				padding: 5px;

				.stat-value {
					font-size: 14px;
				}
			}
		}

		.stat-options {
			flex-direction: column;
		}

		.month-matches-container {
			.month-values-container {
				padding: 5px;

				.match-type-container {
					font-size: 10px;

					.type-stats-container {
						.type-stat-value {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}
