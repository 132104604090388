.upload-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 991px) {
	.upload-container {
		display: flex;
		flex-direction: column;
	}
}
