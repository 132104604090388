.recharts-wrapper {
	width: 500px;
	height: 250px;
}

@media (max-width: 767px) {
	.recharts-wrapper {
		width: 400px !important;
		height: 100% !important;
	}
}

@media (max-width: 575px) {
	.recharts-wrapper {
		width: 260px !important;
		height: 100% !important;
	}
}
