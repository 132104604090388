.player-container {
	.stats-player-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 10px;
		border: 1px solid #ebf1fd;
		padding: 5px 20px;
		background-color: #ebf1fd;
		position: relative;

		&.attendance {
			background-color: #fff;
			border: 2px solid #ebf1fd;
		}

		&.rpe {
			padding: 5px 30px;
		}

		.MuiButtonBase-root {
			padding: 0px;
		}

		.stats-action {
			position: absolute;
			top: 0px;
			right: 05px;
		}

		.player-icon {
			width: 35px;
			height: 35px;
		}

		.player-name {
			font-weight: bold;
			line-height: 1;

			&.match {
				min-width: 200px;
			}

			&.clickable {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.player-stat-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			line-height: 1;

			.stat-value {
				font-size: 16px;
				font-weight: bold;
				min-width: 75px;
				text-align: center;

				// &.small {
				// 	font-size: 18px;
				// }
			}

			.stat-label-icon {
				width: 10px;
				height: 10px;
				border-radius: 50%;

				&.on {
					background-color: #67af67;
				}

				&.off {
					background-color: #e45858;
				}
			}
		}
		.stats-container {
			gap: 100px;
		}
	}
}

// @media (max-width: 1199px) {
// 	.player-container {
// 		.stats-player-container {
// 			margin-top: 10px;
// 		}
// 	}
// }

@media (max-width: 991px) {
	.player-container {
		.stats-player-container {
			padding: 10px 20px;
			flex-direction: column;

			&.attendance {
				flex-direction: row;
				gap: 5px;

				.player-icon {
					width: 30px;
					height: 30px;
				}

				.player-name {
					&.match {
						min-width: 200px;
					}
				}

				.MuiButtonBase-root {
					height: 25px;
					padding: 0px;

					.MuiSvgIcon-root {
						height: 18px;
					}
				}
			}

			.player-name {
				&.match {
					min-width: fit-content;
				}
			}

			.stats-container {
				gap: 35px;
			}
		}
	}
}

@media (max-width: 575px) {
	.player-container {
		.stats-player-container {
			&.attendance {
				flex-direction: column;

				.player-name {
					&.match {
						min-width: fit-content;
					}
				}
			}
		}
	}
}
