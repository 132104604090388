.react-datepicker-wrapper {
	width: 100%;
	margin-bottom: 5px;

	.react-datepicker__input-container {
		input {
			height: 40px;
			border: 1px solid #cccccc;
			border-radius: 5px;
			font-size: 1rem;
			padding: 8.5px 14px;
			width: 100%;
		}
	}
}
