.scout-club-card-container {
	display: flex;
	flex-direction: column;

	.scout-club-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 12px;
		}
	}

	.scout-club-card-info {
		display: flex;
		flex-flow: column;
		line-height: 1.3;

		.info-image {
			flex: 1;
			display: flex;
			justify-content: center;

			img {
				width: 30px;
				height: 30px;
			}
		}

		.info-title {
			font-weight: bold;
			font-size: 16px;
			flex: 2;
		}
		.info-subtitle {
			font-size: 14px;
		}
	}
}

@media (max-width: 991px) {
	.scout-club-card-container {
		.scout-club-card-info {
			.info-title {
				font-size: 14px;
			}
			.info-subtitle {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 767px) {
	.scout-club-card-container {
		.scout-club-card-info {
			.info-title {
				font-size: 16px;
			}
		}
	}
}
