.main-actions-container {
	display: flex;
	align-items: center;
	gap: 10px;

	.main-action {
		// flex: 1;

		.MuiButton-root {
			// min-width: 125px !important;
			width: 100%;
			height: 25px;

			.action-content {
				display: flex;
				align-items: center;
				gap: 10px;
				color: #fff;

				.action-label {
					color: #1876d2;
					font-size: 16px;
					text-transform: none;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.main-actions-container {
		gap: 5px;

		.main-action {
			min-width: 70px;

			.MuiButton-root {
				width: 100%;

				.action-content {
					.action-label {
						font-size: 14px;
					}
				}
			}
		}
	}
}
