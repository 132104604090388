.evaluation-choice {
	gap: 5px;

	.choice-label {
		font-weight: bold;
	}

	.choice-options {
		display: flex;
		align-items: center;
		gap: 10px;

		.single-option {
			border-radius: 10px;
			border: 2px solid lightgray;
			min-width: 80px;
			height: 35px;
			padding: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			text-align: center;
			font-size: 13px;

			&:hover {
				background-color: #cfe5ff;
			}

			&.selected {
				border: 2px solid #8bb6e7;
				color: #000; // Testo bianco per contrasto
			}

			// Stile per l'opzione selezionata, background uguale al bordo
			&.selected.idoneo {
				background-color: #67af67;
				border-color: #67af67;
				color: white; // Testo bianco per contrasto
			}

			&.selected.non-idoneo {
				background-color: #e45858;
				border-color: #e45858;
				color: white; // Testo bianco per contrasto
			}

			&.selected.da-rivedere {
				background-color: #8bb6e7;
				border-color: #8bb6e7;
				color: white; // Testo bianco per contrasto
			}

			&.selected.non-interessante {
				background-color: #e45858;
				border-color: #e45858;
				color: white; // Testo bianco per contrasto
			}
		}
	}

	.rating-options-container {
		justify-content: center;
	}

	// Media Queries
	@media (max-width: 768px) {
		.choice-options {
			// Mantieni in orizzontale
			flex-direction: row;
			gap: 20px;

			.single-option {
				width: 100%; // Opzioni occupano tutta la larghezza
				font-size: 1em; // Riduci la dimensione del testo
			}
		}
	}

	@media (max-width: 480px) {
		.choice-options {
			gap: 5px;

			.single-option {
				font-size: 0.9em;
				padding: 10px;
				min-width: 50px;
				line-height: 1.2;
			}
		}
	}
}
