/* src/components/Rating.css */
.rating-container {
	display: flex;
	flex-direction: column;
}

.rating-options-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}

.rating-box {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	transition: background-color 0.3s;
}

.rating-box.selected {
	font-weight: bold;
	color: black;
}
