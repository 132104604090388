.tqr-card-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.tqr-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-left {
			font-size: 12px;
			font-style: italic;
		}

		.top-right {
			font-size: 12px;
		}
	}

	.tqr-card-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		line-height: 1.3;

		.info-title {
			font-weight: bold;
			font-size: 14px;
		}
	}

	.tqr-card-preview {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.tqr-type {
			display: flex;
			align-items: center;
			gap: 3px;

			img {
				width: 25px;
			}

			.tqr-info {
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 1;
			}
		}

		.tqr-actions {
			display: flex;
			align-items: center;
			gap: 12px;

			.tqr-action {
				display: flex;
				align-items: center;
				gap: 5px;
				font-weight: bold;
				cursor: pointer;

				// &.error {
				// 	.MuiSvgIcon-root {
				// 		color: #e45858;
				// 	}
				// }

				.MuiSvgIcon-root {
					color: #002160;

					&:hover {
						background-color: #ebf1fd;
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.tqr-card-container {
		.tqr-card-info {
			.info-title {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 767px) {
	.tqr-card-container {
		.tqr-card-info {
			.info-title {
				font-size: 14px;
			}
		}
	}
}
