.notes-section-container {
	.MuiSvgIcon-root {
		&:hover {
			background-color: lightgray;
		}
	}

	.notes-title {
		font-weight: bold;
		margin-bottom: 0px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.notes-section {
		max-height: 350px;
		min-height: 100px;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;

		&.full-page {
			max-height: 100%;
		}

		.note-card-box {
			position: relative;

			.MuiCardContent-root {
				padding-bottom: 16px;
			}

			.note-content-container {
				height: 150px;
				overflow-x: hidden;
				overflow-y: auto;
				position: relative;

				.note-box-title {
					font-weight: bold;
				}

				.note-box-author {
					margin-top: 15px;
					font-weight: bold;
				}

				// .notes-actions {
				// 	position: absolute;
				// 	top: 2px;
				// 	right: 4px;
				// }
			}
		}

		.notes-no-data {
			font-style: italic;
			margin: 10px;
		}
	}
}
