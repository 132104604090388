.MuiDialog-container {
	.MuiDialogTitle-root {
		background-color: #f2f2f2;
		padding: 8px 16px;
	}

	.MuiDialogContent-root {
		padding: 20px !important;
	}

	.MuiDialogActions-root {
		border-top: 1px solid #d9d9d9;
	}
}

@media (max-width: 767px) {
	.dialog-container {
		height: 30vh;
	}
}
