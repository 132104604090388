.tabs-container {
	.tabs-header-container {
		margin-bottom: 10px;

		.tabs-header {
			display: flex;
			align-items: center;
			width: 100%;
			gap: 25px;
			height: 25px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			line-height: 1.8;

			.single-tab {
				height: 100%;
				cursor: pointer;
				font-size: 14px;

				&.selected {
					font-weight: bold;
					border-bottom: 2px solid #002160;
				}
			}
		}
	}

	.tab-body {
		height: calc(var(--doc-height) - 85px - 50px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}

@media (max-width: 767px) {
	.tabs-container {
		.tabs-header-container {
			.tabs-header {
				border-bottom: 0px;
				// display: flex;
				// align-items: center;
				// flex-wrap: wrap;
				overflow-x: auto;
				overflow-y: hidden;
				width: 100%;
				gap: 10px;

				.single-tab {
					border: 1px solid #dadada;
					border-radius: 10px;
					padding: 0px 10px;
					white-space: nowrap;

					&.selected {
						border-bottom: 1px solid #dadada;
						background-color: #002160;
						border-color: #002160;
						color: #fff;
					}
				}
			}
		}
		.tab-body {
			height: calc(var(--doc-height) - 120px - 50px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}

@media (max-width: 400px) {
	// .tabs-container {
	// 	.tabs-header {
	// 		gap: 10px !important;

	// 		.single-tab {
	// 			max-width: 65px;
	// 			text-overflow: ellipsis;
	// 			overflow: hidden;
	// 			white-space: nowrap;
	// 		}
	// 	}
	// }
}
