.section-box {
	display: flex;
	align-items: center;
	flex: 1;
	border-radius: 10px;
	border: 1px solid #002160;
	padding: 8px;

	.section-item {
		flex: 2;
		display: flex;
		flex-flow: column;
		align-items: center;

		&.small {
			flex: 1;
			border-right: 1px solid #002160;
		}

		img {
			height: 50px;
		}

		.label {
			font-weight: bold;
			font-size: 15px;
		}

		.value {
			font-size: 12px;
		}
	}

	&:hover {
		cursor: pointer;
		background-color: #ebf1fd;
	}
}

.section-box-dashboard {
	display: flex;
	align-items: center;
	border-radius: 10px;
	border: 1px solid #002160;
	padding: 15px 10px;
	margin: 8px;
	background-color: #fff;
	position: relative;

	.section-item-info {
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: 12px;
		background-color: #cedeff;
		border-radius: 0px 10px 0px 10px;
		padding: 2px 8px;
	}

	.section-item-date-info {
		position: absolute;
		top: 2px;
		left: 6px;

		.light {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #67af67;
		}
	}

	.section-item-dashboard {
		flex: 4;
		display: flex;
		flex-flow: column;
		align-items: center;
		line-height: 1.3;
		margin-top: 6px;

		&.small {
			flex: 1;
			border-right: 1px solid #002160;

			img {
				height: 30px;
			}
			.item-image-label {
				margin-top: 3px;
				font-size: 12px;
			}
		}

		.teams-dashboard {
			font-weight: bold;
			font-size: 13px;
		}

		.value-dashboard {
			font-size: 12px;
		}

		.date-dashboard {
			font-size: 12px;
		}

		.score-dashboard {
			font-size: 14px;
			font-weight: bold;
			min-width: 24px;
		}
	}

	&:hover {
		cursor: pointer;
		background-color: #ebf1fd;
	}
}

@media (max-width: 991px) {
	.section-box {
		.section-item {
			padding: 0px 5px;
			img {
				height: 40px;
			}
		}
	}

	.section-box-dashboard {
		.section-item-dashboard {
			padding: 0px 5px;

			.teams-dashboard {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 767px) {
	.section-box {
		.section-item {
			.value {
				font-size: 16px;
			}
		}
	}

	// .section-box-dashboard {
	// 	.section-item-dashboard {
	// 		.teams-dashboard {
	// 			font-size: 14px;
	// 		}
	// 	}
	// }
}
