.filters-row-container {
	.filters-container {
		box-shadow: 2px 2px 2px 2px #ebebeb;
		border-radius: 10px;
		background-color: #fff;
		margin: 0px 5px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: relative;
		height: 25px;

		&.opened {
			height: 100%;
		}

		.closed {
			&:hover {
				background-color: #ebf1fd;
			}
			.filters-title-container {
				display: flex;
				align-items: center;
				gap: 10px;

				.filters-title {
					font-weight: bold;
				}
				.round {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #002160;
				}
			}

			.filters-icon {
				width: 18px;
				height: 18px;
				display: flex;
				align-items: center;

				.MuiSvgIcon-root {
					width: 18px;
					height: 18px;
				}
			}
		}

		.filters-close {
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}

	.applied-filter {
		border-radius: 10px;
		padding: 2px 10px;
		background-color: #ecebeb;
		font-size: 12px;

		.filter-label {
			font-weight: bold;
		}
	}
}

@media (max-width: 991px) {
	.filters-row-container {
		// flex-direction: column;
	}
}
