.stat-circle-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 85px;

	.stat-container {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 3px solid #002160;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;

		.value {
			font-size: 16px;
			font-weight: bold;
		}
	}
}
