.p-col {
	border-right: 1px solid lightgray;
	padding-right: 10px;
	margin-bottom: 5px;

	&:nth-child(3n) {
		border: 0 none;
	}
	.parameters-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 10px;

		.parameter {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;

			.p-value {
				width: 90px;
				height: 30px;
				// font-size: 16px;
				font-weight: bold;
				border-radius: 10px;
				border: 2px solid #0088d1;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

@media (max-width: 991px) {
	.p-col {
		border-right: 0 none;
		padding-right: 0px;

		.parameters-container {
			gap: 0px;

			.parameter {
				margin: 5px 0px;
				padding: 5px 0px;
				border-bottom: 1px solid #ebf1fd;
			}
		}
	}
}
