.rosters-container {
	border: 1px solid #ebf1fd;
	border-radius: 10px;
	padding: 5px;
	background-color: #ebf1fd;

	.roster-title {
		font-size: 16px;
		font-weight: bold;
		margin: 0px 5px;
	}

	.roster-icon {
		width: 20px;
		height: 20px;
	}
}
