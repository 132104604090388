.player-choice {
	gap: 5px;

	.choice-label {
		font-weight: bold;
	}

	.choice-options {
		display: flex;
		align-items: center;
		gap: 20px;

		.single-option {
			border-radius: 10px;
			border: 2px solid lightgray;
			width: auto;
			height: 40px;
			padding: 0px 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				border-color: #001847;
			}

			&.selected {
				background-color: #ebf1fd;
			}
		}
	}

	.rating-options-container {
		justify-content: center;
	}
}

.exercise-container {
	display: flex;
	align-items: center;
	border-radius: 10px;
	border: 1px solid #ebf1fd;
	padding: 5px 20px;
	position: relative;

	.exercise-counter {
		font-size: 18px;
		font-weight: bold;
		padding-right: 10px;
		border-right: 2px solid #ebf1fd;
	}

	.exercise-text-container {
		display: flex;
		align-items: center;
		flex: 4;
		padding-left: 10px;
		gap: 2rem;

		.exercise-title-container {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			border-right: 2px solid #ebf1fd;

			img {
				width: 30px;
			}

			.exercise-title {
				font-weight: bold;
				width: 250px;
				line-height: 1.2;
			}
		}

		.exercise-notes {
			flex: 1;
		}
	}

	.exercise-duration {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		flex: 1;

		img {
			width: 30px;
		}

		.exercise-duration-text {
			font-weight: bold;
			font-size: 18px;
		}
	}

	.MuiButtonBase-root {
		width: 22px;
		height: 30px;
		padding: 2px 6px;

		.MuiSvgIcon-root {
			width: 18px;
			height: 18px;
		}
	}
}

.alert-container {
	background-color: #ffbbbb;
	padding: 2px 8px;
	border: 1px solid #ffa4a4;
	border-radius: 10px;
	width: fit-content;
}

@media (max-width: 1199px) {
	.exercise-container {
		.exercise-text-container {
			flex-direction: column;
			gap: 0.4rem;
			align-items: start;
			padding: 10px 0px 10px 10px;

			.exercise-title-container {
				border-right: 0 none;

				.exercise-title {
					width: 100%;
				}
			}
		}

		.exercise-actions {
			flex-direction: column;
		}
	}
}

@media (max-width: 575px) {
	.exercise-container {
		flex-direction: column;
		position: relative;

		.MuiButtonBase-root {
			height: 25px;
			padding: 0px;
		}

		.exercise-counter {
			border-right: 0 none;
			border: 1px solid #ebf1fd;
			background-color: #ebf1fd;
			border-radius: 0px 8px;
			padding: 0px;
			width: 30px;
			height: 30px;
			text-align: center;
			position: absolute;
			top: 0px;
			right: 0px;
		}

		.exercise-text-container {
			flex-direction: column;
			gap: 0px;
			align-items: start;
			padding: 25px 10px 10px 10px;
			width: 100%;

			.exercise-title-container {
				border-right: 0 none;

				.exercise-title {
					width: 100%;
				}
			}
		}

		.exercise-duration {
			padding: 5px;
			border-bottom: 1px solid #ebf1fd;
			border-radius: 8px;
			background-color: #ebf1fd;
			margin-bottom: 10px;
			width: 100%;
		}
	}
}
