.attachments-container {
	.attachment-box {
		border: 1px solid rgba(0, 0, 0, 0.12);
		border-radius: 10px;
		max-height: 200px;
		// padding: 10px;
		text-align: center;
		display: flex;
		align-items: center;
		// justify-content: center;
		// flex-direction: column;
		// gap: 10px;
		margin: 5px;

		&.video {
			height: auto;
			max-height: none;
			flex-direction: column;

			.attachment-name {
				border-right: 0px;
			}
		}

		&.image {
			height: auto;

			.attachment-info {
				flex-direction: column;

				.attachment-name {
					margin-bottom: 5px;
					border-right: 0px;
				}
			}
		}

		.attachment-img {
			&.pdf {
				max-height: 175px;
				max-width: 80px;
				object-fit: cover;
			}
			&.png {
				max-height: 80px;
				max-width: 175px;
				object-fit: "cover";
			}
			&.jpeg {
				max-height: 80px;
				max-width: 175px;
				object-fit: "cover";
			}
			&.jpg {
				max-height: 80px;
				max-width: 175px;
				object-fit: "cover";
			}
			&.mov {
				max-height: 100px;
				max-width: 100px;
				object-fit: "cover";
			}
			&.mp4 {
				max-height: 100px;
				max-width: 100px;
				object-fit: "cover";
			}
		}

		p {
			margin-bottom: 0px;
		}

		.attachment-name {
			flex: 2;
			border-right: 1px solid lightgray;
		}
		.attachment-actions {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			// width: 100%;
			gap: 5px;

			.single-action {
				// flex: 1;

				.MuiButtonBase-root {
					width: 100%;
					height: 25px;
				}
			}
		}
	}
}
