.training-text-container {
	display: flex;
	align-items: center;
	width: 100%;

	&.player {
		justify-content: space-around;
		.label {
			flex: none;
		}
		.value {
			flex: none;
			width: 20px;
			height: 20px;

			&.inserted {
				background-color: #67af67;
			}

			&.empty {
				background-color: #e45858;
			}
		}
	}

	.label {
		flex: 1;
	}
	.value {
		flex: 1;
		border-radius: 10px;
		background-color: #ebf1fd;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}
}
