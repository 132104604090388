.categories-list {
	display: flex;
	align-items: center;
	gap: 3px;
	height: 20px;
	// margin: 5px auto;

	.categories-element {
		padding: 2px 6px;
		background-color: #ecebeb;
		border-radius: 10px;
		font-size: 12px;
		line-height: 1;
	}
}

@media (max-width: 575px) {
	.categories-list {
		&.empty {
			display: flex;
		}

		.categories-element {
			font-size: 11px;
		}
	}
}
